import React, {lazy, useState} from "react";
import './gallery.css'
import {FaTimes, FaChevronUp} from "react-icons/fa";

import o1 from "../media/pictures/outside/o1.jpg"
import o2 from "../media/pictures/outside/o2.jpg"
import o3 from "../media/pictures/outside/o3.jpg"

import t1 from "../media/pictures/terrace/t1.jpg"
import t2 from "../media/pictures/terrace/t2.jpg"
import t3 from "../media/pictures/terrace/t3.jpg"
import t4 from "../media/pictures/terrace/t4.jpg"
import t5 from "../media/pictures/terrace/t5.jpg"
import t6 from "../media/pictures/terrace/t6.jpg"
import t7 from "../media/pictures/terrace/t7.jpg"
import t8 from "../media/pictures/terrace/t8.jpg"
import t9 from "../media/pictures/terrace/t9.jpg"
import t10 from "../media/pictures/terrace/t10.jpg"
import t11 from "../media/pictures/terrace/t11.jpg"
import t12 from "../media/pictures/terrace/t12.jpg"
import t13 from "../media/pictures/terrace/t13.jpg"
import t14 from "../media/pictures/terrace/t14.jpg"
import t15 from "../media/pictures/terrace/t15.jpg"

import b1 from "../media/pictures/bedroom/b1.jpg"
import b2 from "../media/pictures/bedroom/b2.jpg"
import b3 from "../media/pictures/bedroom/b3.jpg"
import b4 from "../media/pictures/bedroom/b4.jpg"
import b5 from "../media/pictures/bedroom/b5.jpg"
import b6 from "../media/pictures/bedroom/b6.jpg"
import b7 from "../media/pictures/bedroom/b7.jpg"
import b8 from "../media/pictures/bedroom/b8.jpg"
import b9 from "../media/pictures/bedroom/b9.jpg"
import b10 from "../media/pictures/bedroom/b10.jpg"
import b11 from "../media/pictures/bedroom/b11.jpg"
import b12 from "../media/pictures/bedroom/b12.jpg"
import b13 from "../media/pictures/bedroom/b13.jpg"
import b14 from "../media/pictures/bedroom/b14.jpg"
import b15 from "../media/pictures/bedroom/b15.jpg"
import b16 from "../media/pictures/bedroom/b16.jpg"

import k1 from "../media/pictures/kitchen/k1.jpg"
import k2 from "../media/pictures/kitchen/k2.jpg"
import k3 from "../media/pictures/kitchen/k3.jpg"
import k4 from "../media/pictures/kitchen/k4.jpg"
import k5 from "../media/pictures/kitchen/k5.jpg"
import k6 from "../media/pictures/kitchen/k6.jpg"
import k7 from "../media/pictures/kitchen/k7.jpg"
import k8 from "../media/pictures/kitchen/k8.jpg"

import l1 from "../media/pictures/livingroom/l1.jpg"
import l2 from "../media/pictures/livingroom/l2.jpg"
import l3 from "../media/pictures/livingroom/l3.jpg"
import l4 from "../media/pictures/livingroom/l4.jpg"
import l5 from "../media/pictures/livingroom/l5.jpg"
import l6 from "../media/pictures/livingroom/l6.jpg"
import l7 from "../media/pictures/livingroom/l7.jpg"
import l8 from "../media/pictures/livingroom/l8.jpg"
import l9 from "../media/pictures/livingroom/l9.jpg"
import l10 from "../media/pictures/livingroom/l10.jpg"
import l11 from "../media/pictures/livingroom/l11.jpg"
import l12 from "../media/pictures/livingroom/l12.jpg"
import l13 from "../media/pictures/livingroom/l13.jpg"
import l14 from "../media/pictures/livingroom/l14.jpg"
import l15 from "../media/pictures/livingroom/l15.jpg"
import l16 from "../media/pictures/livingroom/l16.jpg"
import l17 from "../media/pictures/livingroom/l17.jpg"

import bt1 from "../media/pictures/bt/bt1.jpg"
import bt2 from "../media/pictures/bt/bt2.jpg"
import bt3 from "../media/pictures/bt/bt3.jpg"
import bt4 from "../media/pictures/bt/bt4.jpg"
import bt5 from "../media/pictures/bt/bt5.jpg"
import bt6 from "../media/pictures/bt/bt6.jpg"
import bt7 from "../media/pictures/bt/bt7.jpg"
import bt8 from "../media/pictures/bt/bt8.jpg"
import bt9 from "../media/pictures/bt/bt9.jpg"
import bt10 from "../media/pictures/bt/bt10.jpg"

import m1 from "../media/pictures/miscellaneous/m1.jpg"
import m2 from "../media/pictures/miscellaneous/m2.jpg"
import m3 from "../media/pictures/miscellaneous/m3.jpg"
import m4 from "../media/pictures/miscellaneous/m4.jpg"
import m5 from "../media/pictures/miscellaneous/m5.jpg"
import m6 from "../media/pictures/miscellaneous/m6.jpg"
import m7 from "../media/pictures/miscellaneous/m7.jpg"
import m8 from "../media/pictures/miscellaneous/m8.jpg"
import m9 from "../media/pictures/miscellaneous/m9.jpg"
import m10 from "../media/pictures/miscellaneous/m10.jpg"
import m11 from "../media/pictures/miscellaneous/m11.jpg"
import m12 from "../media/pictures/miscellaneous/m12.jpg"

const Gallery = () => {
    let data =[
        {
            id: 1,
            imgSrc: o1,
        },
        {
            id: 2,
            imgSrc: o2,
        },
        {
            id: 3,
            imgSrc: o3,
        },
        {
            id: 4,
            imgSrc: t1,
        },
        {
            id: 5,
            imgSrc: t2,
        },
        {
            id: 6,
            imgSrc: t3,
        },
        {
            id: 7,
            imgSrc: t4,
        },
        {
            id: 8,
            imgSrc: t5,
        },
        {
            id: 9,
            imgSrc: t6,
        },
        {
            id: 10,
            imgSrc: t7,
        },
        {
            id: 11,
            imgSrc: t8,
        },
        {
            id: 12,
            imgSrc: t9,
        },
        {
            id: 14,
            imgSrc: t10,
        },
        {
            id: 15,
            imgSrc: t11,
        },
        {
            id: 16,
            imgSrc: t12,
        },
        {
            id: 17,
            imgSrc: t13,
        },
        {
            id: 18,
            imgSrc: t14,
        },
        {
            id: 19,
            imgSrc: t15,
        },
        {
            id: 20,
            imgSrc: l1,
        },
        {
            id: 21,
            imgSrc: l2,
        },
        {
            id: 22,
            imgSrc: l3,
        },
        {
            id: 23,
            imgSrc: l4,
        },
        {
            id: 24,
            imgSrc: l5
            ,
        },
        {
            id: 25,
            imgSrc: l6,
        },
        {
            id: 26,
            imgSrc: l7,
        },
        {
            id: 27,
            imgSrc: l8
            ,
        },
        {
            id: 28,
            imgSrc: l9,
        },
        {
            id: 29,
            imgSrc: l10,
        },
        {
            id: 30,
            imgSrc: l11,
        },
        {
            id: 31,
            imgSrc: l12,
        },
        {
            id: 32,
            imgSrc: l13,
        },
        {
            id: 33,
            imgSrc: l14,
        },
        {
            id: 34,
            imgSrc: l15,
        },
        {
            id: 35,
            imgSrc: l16,
        },
        {
            id: 36,
            imgSrc: l17,
        },
        {
            id: 37,
            imgSrc: b1,
        },
        {
            id: 38,
            imgSrc: b2,
        },
        {
            id: 39,
            imgSrc: b3,
        },
        {
            id: 40,
            imgSrc: b4,
        },
        {
            id: 41,
            imgSrc: b5,
        },
        {
            id: 42,
            imgSrc: b6,
        },
        {
            id: 43,
            imgSrc: b7,
        },
        {
            id: 44,
            imgSrc: b8,
        },
        {
            id: 45,
            imgSrc: b9,
        },
        {
            id: 46,
            imgSrc: b10,
        },
        {
            id: 47,
            imgSrc: b11,
        },
        {
            id: 48,
            imgSrc: b12,
        },
        {
            id: 48,
            imgSrc: b13,
        },
        {
            id: 48,
            imgSrc: b14,
        },
        {
            id: 49,
            imgSrc: b15,
        },
        {
            id: 50,
            imgSrc: b16,
        },
        {
            id: 51,
            imgSrc: k1,
        },
        {
            id: 52,
            imgSrc: k2,
        },
        {
            id: 53,
            imgSrc: k3,
        },
        {
            id: 54,
            imgSrc: k4,
        },
        {
            id: 55,
            imgSrc: k5,
        },
        {
            id: 56,
            imgSrc: k6,
        },
        {
            id: 57,
            imgSrc: k7,
        },
        {
            id: 58,
            imgSrc: k8,
        },
        {
            id: 59,
            imgSrc: bt1,
        },
        {
            id: 60,
            imgSrc: bt2,
        },
        {
            id: 61,
            imgSrc: bt3,
        },
        {
            id: 62,
            imgSrc: bt4,
        },
        {
            id: 63,
            imgSrc: bt5,
        },
        {
            id: 64,
            imgSrc: bt6,
        },
        {
            id: 65,
            imgSrc: bt7,
        },
        {
            id: 66,
            imgSrc: bt8,
        },
        {
            id: 67,
            imgSrc: bt9,
        },
        {
            id: 68,
            imgSrc: bt10,
        },
        {
            id: 69,
            imgSrc: m3,
        },
        {
            id: 70,
            imgSrc: m4,
        },
        {
            id: 71,
            imgSrc: m5,
        },
        {
            id: 72,
            imgSrc: m6,
        },
        {
            id: 73,
            imgSrc: m7,
        },
        {
            id: 74,
            imgSrc: m8,
        },
        {
            id: 75,
            imgSrc: m9,
        },
        {
            id: 76,
            imgSrc: m10,
        },
        {
            id: 77,
            imgSrc: m11,
        },
        {
            id: 78,
            imgSrc: m12,
        },
        {
            id: 79,
            imgSrc: m2,
        },
        {
            id: 80,
            imgSrc: m1,
        },
    ]

    const [model, setModel] = useState(false)
    const [tempimgSrc, setTempImgSrc] = useState('')

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }

    return(
        <>
            <div className={model? 'model open' : 'model'}>
                <img src={tempimgSrc}/>
                <FaTimes onClick={() => setModel(false)}/>
            </div>

            <div className={'gallery'}>
                    {data.map((item, index)=>{
                        return (
                            <div className={'pics'} key={index} onClick={() => getImg(item.imgSrc) }>
                                <img src={item.imgSrc} style={{width: '100%'}}/>
                            </div>
                        )
                    })}
            </div>
            <button className={'call-buton'}>
                <a className={'cc-calto-action-ripple'} href="/#">
                    <i className="fa fa-phone" aria-hidden="true">
                        <FaChevronUp/>
                    </i>
                </a>
            </button>
        </>
    )
}

export default Gallery;